import React, { Component } from 'react';
import '../ProjectDefault.scss';
import javascriptIcon from '../../../../images/icons/javascript.svg';
import reactIcon from '../../../../images/icons/reactjs.svg';
import htmlIcon from '../../../../images/icons/html5.png';
import sassIcon from '../../../../images/icons/sass.svg';
import gitIcon from '../../../../images/icons/git.png';
import nodeIcon from '../../../../images/icons/nodejs1.png';
import npmIcon from '../../../../images/icons/npm.png';
import auth0Icon from '../../../../images/icons/auth0.svg';
import digitaloceacnIcon from '../../../../images/icons/digitalocean.svg';
import stripeIcon from '../../../../images/icons/stripe.png';
import graphqlIcon from '../../../../images/icons/graphql.svg';
import herokuIcon from '../../../../images/icons/heroku.svg';
import zenhubIcon from '../../../../images/icons/zenhub.svg';
import redisIcon from '../../../../images/icons/redis.svg';
import nodemailerIcon from '../../../../images/icons/nodemailer.png';
import melotree2 from '../../../../images/screenshots/melotree/melotree2.jpg';
import melotree6 from '../../../../images/screenshots/melotree/melotree6.png';
import melotree7 from '../../../../images/screenshots/melotree/melotree7.png';
import melotree8 from '../../../../images/screenshots/melotree/melotree8.png';

class MeloTree extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      projectScreenshot: null,
    };
  }

  modalImageChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.src,
      },
      this.toggleModal
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    return (
      <div className='projectPageContainer'>
        <div
          className={this.state.showModal ? 'showImageModal' : 'hideImageModal'}
          onClick={this.toggleModal}
        >
          <div className='imageModalContainer'>
            <img
              className='modalImage'
              src={this.state.projectScreenshot}
              alt='project screenshot'
            />
          </div>
        </div>
        <div className='projectImageContainer'>
          <img
            className='projectImage'
            src={melotree2}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={melotree6}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={melotree7}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={melotree8}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
        </div>
        <div className='projectInfoMainContainer'>
          <div className='projectInfoContainer1'>
            <div className='projectNameDatesContainer'>
              <p className='projectName'>MeloTree</p>
            </div>
            <div className='projectTechContainer'>
              <div className='techContainer'>
                <img className='techImage' src={zenhubIcon} alt='tech icon' />
                <p className='techName'>ZenHub</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={reactIcon} alt='tech icon' />
                <p className='techName'>ReactJS</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={javascriptIcon}
                  alt='tech icon'
                />
                <p className='techName'>JavaScript</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={htmlIcon} alt='tech icon' />
                <p className='techName'>HTML5</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={sassIcon} alt='tech icon' />
                <p className='techName'>Sass</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={nodeIcon} alt='tech icon' />
                <p className='techName'>NodeJS</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={npmIcon} alt='tech icon' />
                <p className='techName'>NPM</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={redisIcon} alt='tech icon' />
                <p className='techName'>Redis</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={gitIcon} alt='tech icon' />
                <p className='techName'>Git</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={graphqlIcon} alt='tech icon' />
                <p className='techName'>GraphQL</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={herokuIcon} alt='tech icon' />
                <p className='techName'>Heroku</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={auth0Icon} alt='tech icon' />
                <p className='techName'>Auth0</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={digitaloceacnIcon}
                  alt='tech icon'
                />
                <p className='techName'>DigitalOcean</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={stripeIcon} alt='tech icon' />
                <p className='techName'>Stripe</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={nodemailerIcon}
                  alt='tech icon'
                />
                <p className='techName'>Nodemailer</p>
              </div>
            </div>
          </div>
          <div className='projectInfoContainer2'>
            <div className='projectLinksContainer'>
              <a
                href='https://github.com/csgarcia612/MeloTree'
                target='_blank'
                rel='noopener noreferrer'
                title='GitHub Repository - https://github.com/csgarcia612/MeloTree'
              >
                GitHub Repository
              </a>
            </div>
            <div className='projectSummaryContainer'>
              <p className='projectSummary'>
                Developed as a ticket purchasing website. Users are able to
                search and filter through a live updating database of music
                concerts taking place in cities across the US. They can then
                view specific details about individual music concerts and the
                venues holding them. A mock credit card using Stripe allows
                users to complete a purchase through the site and then a receipt
                is emailed to the user using Nodemailer.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MeloTree;
