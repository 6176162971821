import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Work.scss';
import pawsfurlove1 from '../../images/screenshots/pawsfurlove/pawsfurlove1.png';
import melotree1 from '../../images/screenshots/melotree/melotree1.jpg';
import swansonquotes1 from '../../images/screenshots/swansonquotes/swansonquotes5.png';
import pdg4dupage1 from '../../images/screenshots/pdg4dupage/pdg4dupage1.png';

export default class Work extends Component {
  render() {
    return (
      <div className='workPageContainer'>
        <NavLink exact to='/work/pdg4dupage' className='projectNavLink'>
          <div className='projectContainer'>
            <div className='projectImageContainer'>
              <img
                className='projectImage'
                src={pdg4dupage1}
                alt='project screenshot'
              />
            </div>
            <div className='projectTitleContainer'>
              <p className='projectName1'>Citizens For</p>
              <p className='projectName2'>Paula Deacon Garcia</p>
            </div>
          </div>
        </NavLink>
        <NavLink exact to='/work/swansonquotes' className='projectNavLink'>
          <div className='projectContainer'>
            <div className='projectImageContainer'>
              <img
                className='projectImage'
                src={swansonquotes1}
                alt='project screenshot'
              />
            </div>
            <div className='projectTitleContainer'>
              <p className='projectName1'>Ron Swanson Quotes</p>
            </div>
          </div>
        </NavLink>
        <NavLink exact to='/work/melotree' className='projectNavLink'>
          <div className='projectContainer'>
            <div className='projectImageContainer'>
              <img
                className='projectImage'
                src={melotree1}
                alt='project screenshot'
              />
            </div>
            <div className='projectTitleContainer'>
              <p className='projectName1'>MeloTree</p>
            </div>
          </div>
        </NavLink>
        <NavLink exact to='/work/pawsfurlove' className='projectNavLink'>
          <div className='projectContainer'>
            <div className='projectImageContainer'>
              <img
                className='projectImage'
                src={pawsfurlove1}
                alt='project screenshot'
              />
            </div>
            <div className='projectTitleContainer'>
              <p className='projectName1'>Paws Fur Love</p>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}
