import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import Homepage from './components/Homepage/Homepage';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Skills from './components/Skills/Skills';
// import Journal from './components/Journal/Journal';
import Work from './components/Work/Work';
import PDG4DuPage from './components/Work/projects/PDG4DuPage/PDG4DuPage';
import SwansonQuotes from './components/Work/projects/SwansonQuotes/SwansonQuotes';
import MeloTree from './components/Work/projects/MeloTree/MeloTree';
import PawsFurLove from './components/Work/projects/PawsFurLove/PawsFurLove';

export default (
  <Switch>
    {/* <Route exact path='/' component={Homepage} /> */}
    <Route exact path='/' component={About} />
    <Route exact path='/work' component={Work} />
    <Route path='/work/pdg4dupage' component={PDG4DuPage} />
    <Route path='/work/swansonquotes' component={SwansonQuotes} />
    <Route path='/work/melotree' component={MeloTree} />
    <Route path='/work/pawsfurlove' component={PawsFurLove} />
    <Route path='/contact' component={Contact} />
    <Route path='/skills' component={Skills} />
    {/* <Route path='/about' component={About} /> */}
    {/* <Route path='/journal' component={Journal} /> */}
  </Switch>
);
