import React, { Component } from 'react';
import './Skills.scss';
import vscodeIcon from '../../images/icons/vscode.svg';
import reactIcon from '../../images/icons/reactjs.svg';
import javascriptIcon from '../../images/icons/javascript.svg';
import htmlIcon from '../../images/icons/html5.png';
import cssIcon from '../../images/icons/css3.png';
import sassIcon from '../../images/icons/sass.svg';
import gitIcon from '../../images/icons/git.png';
import nodeIcon from '../../images/icons/nodejs1.png';
import npmIcon from '../../images/icons/npm.png';
import yarnIcon from '../../images/icons/yarn.svg';
import postgresqlIcon from '../../images/icons/postgresql.svg';
import digitaloceacnIcon from '../../images/icons/digitalocean.svg';
import stripeIcon from '../../images/icons/stripe.png';
import trelloIcon from '../../images/icons/trello.png';
import herokuIcon from '../../images/icons/heroku.svg';
import nodemailerIcon from '../../images/icons/nodemailer.png';
import githubIcon from '../../images/icons/github.png';
import auth0Icon from '../../images/icons/auth0.svg';
import zenhubIcon from '../../images/icons/zenhub.svg';
import illustratorIcon from '../../images/icons/adobeillustrator.svg';
import xdIcon from '../../images/icons/adobexd.svg';
import msofficeIcon from '../../images/icons/msoffice.svg';
import reduxIcon from '../../images/icons/redux.svg';

export default class About extends Component {
  render() {
    return (
      <div className='skillsPageContainer'>
        {/* <span className='separator' /> */}
        {/* Seperate into front-end, back-end, and additional technologies groups */}
        <div className='linkIconTitleContainer'>
          <p className='linkIconTitle'>Technology I Have Worked With</p>
        </div>
        <div className='linkIconsContainer'>
          <div className='techContainer'>
            <img className='techImage' src={javascriptIcon} alt='tech icon' />
            <p className='techName'>JavaScript</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={reactIcon} alt='tech icon' />
            <p className='techName'>ReactJS</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={htmlIcon} alt='tech icon' />
            <p className='techName'>HTML</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={cssIcon} alt='tech icon' />
            <p className='techName'>CSS</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={sassIcon} alt='tech icon' />
            <p className='techName'>Sass</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={nodeIcon} alt='tech icon' />
            <p className='techName'>NodeJS</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={npmIcon} alt='tech icon' />
            <p className='techName'>NPM</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={yarnIcon} alt='tech icon' />
            <p className='techName'>Yarn</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={postgresqlIcon} alt='tech icon' />
            <p className='techName'>PostgreSQL</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={reduxIcon} alt='tech icon' />
            <p className='techName'>Redux</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={gitIcon} alt='tech icon' />
            <p className='techName'>Git</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={githubIcon} alt='tech icon' />
            <p className='techName'>GitHub</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={herokuIcon} alt='tech icon' />
            <p className='techName'>Heroku</p>
          </div>
          <div className='techContainer'>
            <img
              className='techImage'
              src={digitaloceacnIcon}
              alt='tech icon'
            />
            <p className='techName'>DigitalOcean</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={auth0Icon} alt='tech icon' />
            <p className='techName'>Auth0</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={stripeIcon} alt='tech icon' />
            <p className='techName'>Stripe</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={nodemailerIcon} alt='tech icon' />
            <p className='techName'>Nodemailer</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={trelloIcon} alt='tech icon' />
            <p className='techName'>Trello</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={zenhubIcon} alt='tech icon' />
            <p className='techName'>ZenHub</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={vscodeIcon} alt='tech icon' />
            <p className='techName'>VS Code</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={msofficeIcon} alt='tech icon' />
            <p className='techName'>MS Office</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={illustratorIcon} alt='tech icon' />
            <p className='techName'>Adobe</p>
            <p className='techName'>Illustrator</p>
          </div>
          <div className='techContainer'>
            <img className='techImage' src={xdIcon} alt='tech icon' />
            <p className='techName'>Adobe</p>
            <p className='techName'>XD</p>
          </div>
        </div>
      </div>
    );
  }
}
