import React, { Component } from 'react';
import '../ProjectDefault.scss';
import javascriptIcon from '../../../../images/icons/javascript.svg';
import reactIcon from '../../../../images/icons/reactjs.svg';
import htmlIcon from '../../../../images/icons/html5.png';
import sassIcon from '../../../../images/icons/sass.svg';
import gitIcon from '../../../../images/icons/git.png';
import nodeIcon from '../../../../images/icons/nodejs1.png';
import npmIcon from '../../../../images/icons/npm.png';
import digitaloceacnIcon from '../../../../images/icons/digitalocean.svg';
import trelloIcon from '../../../../images/icons/trello.png';
import nodemailerIcon from '../../../../images/icons/nodemailer.png';
import pdg4dupage1 from '../../../../images/screenshots/pdg4dupage/pdg4dupage1.png';
import pdg4dupage2 from '../../../../images/screenshots/pdg4dupage/pdg4dupage2.jpg';
import pdg4dupage3 from '../../../../images/screenshots/pdg4dupage/pdg4dupage3.png';
import pdg4dupage4 from '../../../../images/screenshots/pdg4dupage/pdg4dupage4.png';

class PDG4DuPage extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      projectScreenshot: null,
    };
  }

  modalImageChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.src,
      },
      this.toggleModal
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    return (
      <div className='projectPageContainer'>
        <div
          className={this.state.showModal ? 'showImageModal' : 'hideImageModal'}
          onClick={this.toggleModal}
        >
          <div className='imageModalContainer'>
            <img
              className='modalImage'
              src={this.state.projectScreenshot}
              alt='project screenshot'
            />
          </div>
        </div>
        <div className='projectImageContainer'>
          <img
            className='projectImage'
            src={pdg4dupage1}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pdg4dupage2}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pdg4dupage3}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pdg4dupage4}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
        </div>
        <div className='projectInfoMainContainer'>
          <div className='projectInfoContainer1'>
            <div className='projectNameDatesContainer'>
              <a
                href='https://www.pdg4dupage.com'
                target='_blank'
                rel='noopener noreferrer'
                title='Citizens For Paula Deacon Garcia - www.pdg4dupage.com'
                className='projectName'
              >
                PDG 4 DuPage
              </a>
            </div>
            <div className='projectTechContainer'>
              <div className='techContainer'>
                <img className='techImage' src={trelloIcon} alt='tech icon' />
                <p className='techName'>Trello</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={reactIcon} alt='tech icon' />
                <p className='techName'>ReactJS</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={javascriptIcon}
                  alt='tech icon'
                />
                <p className='techName'>JavaScript</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={htmlIcon} alt='tech icon' />
                <p className='techName'>HTML5</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={sassIcon} alt='tech icon' />
                <p className='techName'>Sass</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={nodeIcon} alt='tech icon' />
                <p className='techName'>NodeJS</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={npmIcon} alt='tech icon' />
                <p className='techName'>NPM</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={gitIcon} alt='tech icon' />
                <p className='techName'>Git</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={digitaloceacnIcon}
                  alt='tech icon'
                />
                <p className='techName'>DigitalOcean</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={nodemailerIcon}
                  alt='tech icon'
                />
                <p className='techName'>Nodemailer</p>
              </div>
            </div>
          </div>
          <div className='projectInfoContainer2'>
            <div className='projectLinksContainer'>
              <a
                href='https://github.com/csgarcia612/CitizensForPDG'
                target='_blank'
                rel='noopener noreferrer'
                title='GitHub Repository - https://github.com/csgarcia612/CitizensForPDG'
              >
                GitHub Repository
              </a>
            </div>
            <div className='projectSummaryContainer'>
              <p className='projectSummary'>
                Developed for an active political campaign running during the
                2020 election cycle. Users are able to read about the candidate,
                see new campaign updates, access links for more election
                information and how to register to vote, and email the candidate
                using a built in contact form.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PDG4DuPage;
