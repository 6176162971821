import React, { Component } from 'react';
import './Header.scss';
import { NavLink } from 'react-router-dom';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      showMobileMenu: false
    };
  }

  toggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
  };

  render() {
    const { showMobileMenu } = this.state;
    return (
      <div className='headerContainer'>
        <div className='menuContainer'>
          {/* <NavLink exact to='/' className='navLinks1' activeClassName='active'>
            Home
          </NavLink> */}
          <NavLink exact to='/' className='navLinks1' activeClassName='active'>
            About
          </NavLink>
          <NavLink to='/work' className='navLinks1' activeClassName='active'>
            Work
          </NavLink>
          <NavLink to='/skills' className='navLinks1' activeClassName='active'>
            Skills
          </NavLink>
          {/* <NavLink to='/journal' className='navLinks1' activeClassName='active'>
            Journal
          </NavLink> */}
          {/* <NavLink to='/about' className='navLinks1' activeClassName='active'>
            About
          </NavLink> */}
          <NavLink
            to='/contact'
            className='navLinks2'
            activeClassName='active2'
          >
            Contact
          </NavLink>
        </div>
        <div className='mobileMenuContainer'>
          <div className='mobileMenuBtnContainer'>
            <button className='mobileMenuBtn' onClick={this.toggleMobileMenu}>
              {showMobileMenu ? 'Close' : 'Menu'}
            </button>
          </div>
          <div className={showMobileMenu ? 'showMobileMenu' : 'hideMobileMenu'}>
            <ul>
              {/* <li>
                <a href='/'>Home</a>
              </li> */}
              <li>
                <a href='/'>About</a>
              </li>
              <li>
                <a href='/work'>Work</a>
              </li>
              <li>
                <a href='/skills'>Skills</a>
              </li>
              {/* <li>
                <a href='/journal'>Journal</a>
              </li> */}
              {/* <li>
                <a href='/about'>About</a>
              </li> */}
              <li>
                <a href='/contact'>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
