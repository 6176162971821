import React, { Component } from 'react';
import './About.scss';
import headshot2 from '../../images/headshot2.jpg';

export default class About extends Component {
  render() {
    return (
      <div className='aboutPageContainer'>
        <div className='aboutMainContainer'>
          <div className='aboutNameImageContainer'>
            <div className='aboutNameContainer'>
              <p className='aboutName'>Christopher Garcia</p>
            </div>
            <div className='aboutImageContainer'>
              <img
                className='socialHeadshot'
                src={headshot2}
                alt='social headshot'
              />
            </div>
            <div className='aboutTitleContainer'>
              <p className='aboutTitle'>Full Stack</p>
              <p className='aboutTitle'>Developer / Designer</p>
            </div>
          </div>
          <div className='aboutBioContainer'>
            <p className='personalBio1'>
              I am a developer who enjoys finding elegant and innovative
              solutions to complex problems. I have always been drawn to
              positions with responsibilities in technology and my natural
              curiosity to look behind the scenes and find out how things come
              together has driven me to continue to learn in this ever-evolving
              industry.
            </p>
            <p className='personalBio2'>
              When I'm not learning about and using new technologies in web
              development, I enjoy binging on a good movie or TV show, as well
              as going to a concert for one of my many favorite musicians. I
              have a passion for animals, especially my two dogs, and being
              outdoors enjoying nature.
            </p>
            <p className='personalBio3'>
              I have a very creative mind and usually can be found working on a
              DIY project around my home or adding more color to the gardens in
              my yard.
            </p>
            <p className='personalBio4'>
              Finally, I want to say a big thank you to my parents who continue
              to encourage my creativity and desire to learn more about new and
              interesting things.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
