import React, { Component } from 'react';
import '../ProjectDefault.scss';
import javascriptIcon from '../../../../images/icons/javascript.svg';
import reactIcon from '../../../../images/icons/reactjs.svg';
import htmlIcon from '../../../../images/icons/html5.png';
import sassIcon from '../../../../images/icons/sass.svg';
import gitIcon from '../../../../images/icons/git.png';
import nodeIcon from '../../../../images/icons/nodejs1.png';
import npmIcon from '../../../../images/icons/npm.png';
import postgresqlIcon from '../../../../images/icons/postgresql.svg';
import herokuIcon from '../../../../images/icons/heroku.svg';
import swansonquotes1 from '../../../../images/screenshots/swansonquotes/swansonquotes2.png';
import swansonquotes2 from '../../../../images/screenshots/swansonquotes/swansonquotes4.png';
import swansonquotes3 from '../../../../images/screenshots/swansonquotes/swansonquotes5.png';

class MeloTree extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      projectScreenshot: null
    };
  }

  modalImageChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.src
      },
      this.toggleModal
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    return (
      <div className='projectPageContainer'>
        <div
          className={this.state.showModal ? 'showImageModal' : 'hideImageModal'}
          onClick={this.toggleModal}
        >
          <div className='imageModalContainer'>
            <img
              className='modalImage'
              src={this.state.projectScreenshot}
              alt='project screenshot'
            />
          </div>
        </div>
        <div className='projectImageContainer'>
          <img
            className='projectImage'
            src={swansonquotes1}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={swansonquotes2}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={swansonquotes3}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
        </div>
        <div className='projectInfoMainContainer'>
          <div className='projectInfoContainer1'>
            <div className='projectNameDatesContainer'>
              <p className='projectName'>Ron Swanson Quotes</p>
            </div>
            <div className='projectTechContainer'>
              <div className='techContainer'>
                <img className='techImage' src={reactIcon} alt='tech icon' />
                <p className='techName'>ReactJS</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={javascriptIcon}
                  alt='tech icon'
                />
                <p className='techName'>JavaScript</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={htmlIcon} alt='tech icon' />
                <p className='techName'>HTML5</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={sassIcon} alt='tech icon' />
                <p className='techName'>Sass</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={nodeIcon} alt='tech icon' />
                <p className='techName'>NodeJS</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={npmIcon} alt='tech icon' />
                <p className='techName'>NPM</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={gitIcon} alt='tech icon' />
                <p className='techName'>Git</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={postgresqlIcon}
                  alt='tech icon'
                />
                <p className='techName'>PostgreSQL</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={herokuIcon} alt='tech icon' />
                <p className='techName'>Heroku</p>
              </div>
            </div>
          </div>
          <div className='projectInfoContainer2'>
            <div className='projectLinksContainer'>
              <a
                href='https://github.com/csgarcia612/swanson-quotes'
                target='_blank'
                rel='noopener noreferrer'
                title='GitHub Repository - https://github.com/csgarcia612/swanson-quotes'
              >
                GitHub Repository
              </a>
            </div>
            <div className='projectSummaryContainer'>
              <p className='projectSummary'>
                Developed as a static random quote generator website. Users are
                able to choose the length of the random quote that will appear
                on screen. They can also rate the quote and the average rating,
                from all users, for the quote will appear on screen.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MeloTree;
