import React, { Component } from 'react';
import '../ProjectDefault.scss';
import javascriptIcon from '../../../../images/icons/javascript.svg';
import reactIcon from '../../../../images/icons/reactjs.svg';
import htmlIcon from '../../../../images/icons/html5.png';
import cssIcon from '../../../../images/icons/css3.png';
import gitIcon from '../../../../images/icons/git.png';
import nodeIcon from '../../../../images/icons/nodejs1.png';
import npmIcon from '../../../../images/icons/npm.png';
import auth0Icon from '../../../../images/icons/auth0.svg';
import postgresqlIcon from '../../../../images/icons/postgresql.svg';
import digitaloceacnIcon from '../../../../images/icons/digitalocean.svg';
import stripeIcon from '../../../../images/icons/stripe.png';
import trelloIcon from '../../../../images/icons/trello.png';
import herokuIcon from '../../../../images/icons/heroku.svg';
import nodemailerIcon from '../../../../images/icons/nodemailer.png';
import pawsfurlove1 from '../../../../images/screenshots/pawsfurlove/pawsfurlove1.png';
import pawsfurlove2 from '../../../../images/screenshots/pawsfurlove/pawsfurlove2.png';
import pawsfurlove3 from '../../../../images/screenshots/pawsfurlove/pawsfurlove3.png';
import pawsfurlove4 from '../../../../images/screenshots/pawsfurlove/pawsfurlove4.png';
import pawsfurlove5 from '../../../../images/screenshots/pawsfurlove/pawsfurlove5.png';
import pawsfurlove6 from '../../../../images/screenshots/pawsfurlove/pawsfurlove6.png';

class PawsFurLove extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      projectScreenshot: null,
    };
  }

  modalImageChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.src,
      },
      this.toggleModal
    );
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    return (
      <div className='projectPageContainer'>
        <div
          className={this.state.showModal ? 'showImageModal' : 'hideImageModal'}
          onClick={this.toggleModal}
        >
          <div className='imageModalContainer'>
            <img
              className='modalImage'
              src={this.state.projectScreenshot}
              alt='project screenshot'
            />
          </div>
        </div>
        <div className='projectImageContainer'>
          <img
            className='projectImage'
            src={pawsfurlove1}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pawsfurlove2}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pawsfurlove3}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
          <img
            className='projectImage'
            src={pawsfurlove6}
            name='projectScreenshot'
            onClick={this.modalImageChange}
            alt='project screenshot'
          />
        </div>
        <div className='projectInfoMainContainer'>
          <div className='projectInfoContainer1'>
            <div className='projectNameDatesContainer'>
              <p className='projectName'>Paws Fur Love</p>
            </div>
            <div className='projectTechContainer'>
              <div className='techContainer'>
                <img className='techImage' src={trelloIcon} alt='tech icon' />
                <p className='techName'>Trello</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={reactIcon} alt='tech icon' />
                <p className='techName'>ReactJS</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={javascriptIcon}
                  alt='tech icon'
                />
                <p className='techName'>JavaScript</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={htmlIcon} alt='tech icon' />
                <p className='techName'>HTML5</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={cssIcon} alt='tech icon' />
                <p className='techName'>CSS3</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={nodeIcon} alt='tech icon' />
                <p className='techName'>NodeJS</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={npmIcon} alt='tech icon' />
                <p className='techName'>NPM</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={gitIcon} alt='tech icon' />
                <p className='techName'>Git</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={postgresqlIcon}
                  alt='tech icon'
                />
                <p className='techName'>PostgreSQL</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={herokuIcon} alt='tech icon' />
                <p className='techName'>Heroku</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={auth0Icon} alt='tech icon' />
                <p className='techName'>Auth0</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={digitaloceacnIcon}
                  alt='tech icon'
                />
                <p className='techName'>DigitalOcean</p>
              </div>
              <div className='techContainer'>
                <img className='techImage' src={stripeIcon} alt='tech icon' />
                <p className='techName'>Stripe</p>
              </div>
              <div className='techContainer'>
                <img
                  className='techImage'
                  src={nodemailerIcon}
                  alt='tech icon'
                />
                <p className='techName'>Nodemailer</p>
              </div>
            </div>
          </div>
          <div className='projectInfoContainer2'>
            <div className='projectLinksContainer'>
              <a
                href='https://github.com/csgarcia612/personal-project'
                target='_blank'
                rel='noopener noreferrer'
                title='GitHub Repository - https://github.com/csgarcia612/personal-project'
              >
                GitHub Repository
              </a>
            </div>
            <div className='projectSummaryContainer'>
              <p className='projectSummary'>
                Developed as a pet adoption website that allows users to filter
                through a static database of dogs available for adoption
                gathered from petfinder.com. Users then are able to see details
                about individual dogs and what animal rescue they are at
                currently. A mock credit card charge using Stripe allows users
                to donate to the website. Users are also able to contact the
                site developers with a built in Nodemailer form.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PawsFurLove;
